/* src/styles/VansPage.module.css */
.container {
    padding: 20px;
    min-height: calc(100vh - 80px); /* Adjust height to keep footer at bottom */
    display: flex;
    flex-direction: column;
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .card {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .cardImage {
    object-fit: cover;
  }
  
  .cardTitle {
    font-weight: bold;
    margin-top: 10px;
  }
  
  .cardSubtitle {
    margin-top: 5px;
  }
  
  .button {
    background-color: #ffc000 !important;
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    margin-top: 10px;
  }
  
  .link {
    text-decoration: none;
    color: inherit; /* Ensures link text color is inherited */
    display: block; /* Makes the entire link block clickable */
  }
  
  /* Ensure the footer remains minimal */
  .footer {
    background-color: #f4f4f4; 
    padding: 0px 0px; 
    text-align: center; 
    color: #333; 
    margin-top: auto; /* Push footer to bottom if page content is short */
  }
  
  .footerText {
    margin: 0; 
    font-size: 14px; 
    line-height: 1.5; 
  }
  