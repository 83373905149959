.footer {
  background-color: #ffffff;
  padding: 15px 0; /* Намалена височина на фуутера */
  color: #000000;
  border-top: 1px solid #ddd;
}

.footerContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  max-width: auto;
  margin: 0 auto;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .footerContent {
    flex-direction: row; /* Layout in row on larger screens */
  }
}

.footerSection {
  flex: 1;
  min-width: 200px;
  margin: 10px; /* Reduced margin */
}

.logo {
  width: 250px; /* Увеличен размер на логото */
}

.sectionTitle {
  font-weight: bold!important;
  margin-bottom: 0px; /* Намален интервал под заглавията */
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list li {
  margin-bottom: 6px; /* Намален интервал между елементите */
}

.link {
  color: #000000;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
