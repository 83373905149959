/* Ensure Roboto font is used by importing it */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

.container {
  padding: 20px !important;
  font-family: 'Roboto', sans-serif !important;
}

.title {
  text-align: center !important;
  margin-bottom: 20px !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  color: #000 !important; /* Black text for the title */
  font-family: 'Roboto', sans-serif !important;
}

.imageContainer {
  position: relative !important;
  border-radius: 8px !important;
  overflow: hidden !important;
}

.image {
  width: 100% !important;
  border-radius: 8px !important;
}

.button {
  position: absolute !important;
  bottom: 10px !important;
  left: 10px !important;
  background-color: #ffc000 !important; /* Gold button color */
  color: #ffffff !important; /* White text color */
  padding: 10px 20px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  border: none !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
}

.button:hover {
  background-color: #e6a700 !important; /* Darker gold on hover */
}

.infoContainer {
  padding: 20px !important;
  border: 1px solid #ffc000 !important; /* Gold border */
  border-radius: 8px !important;
  font-family: 'Roboto', sans-serif !important;
}

.infoTitle {
  margin-bottom: 10px !important;
  font-weight: bold !important;
  color: #000 !important; /* Black text */
}

.infoText {
  color: #000 !important; /* Black text */
  margin-bottom: 10px !important;
}

.emergencyText {
  font-weight: bold !important;
  color: #000 !important; /* Black text */
}

.emergencyText2 {
  font-weight: bold !important;
  color: #ffc000 !important;
}
