/* Import Google Font with specific weights */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

/* Ensure the app bar has a white background */
.appBar {
  background-color: #ffffff !important; /* Force white background */
  color: #000000 !important; /* Default text color set to black */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
  height: 82px; /* Height of the navbar */
  font-family: 'Roboto', sans-serif !important; /* Use Roboto font */
  font-weight: 'bold';
}

/* Toolbar styles */
.toolbar {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space out items evenly */
  align-items: center; /* Align items vertically centered */
  padding: 0 20px; /* Increased padding for better spacing */
  height: 100%; /* Ensure the toolbar takes full height of the navbar */
  font-family: 'Roboto', sans-serif!important; /* Ensure font consistency */
}

/* Logo link styles */
.logoLink {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Align items vertically centered */
  margin: 0; /* Center the logo horizontally */
}

/* Desktop logo styles */
.desktopLogo {
  height: 50px; /* Adjust height of the logo */
  display: block; /* Ensure desktop logo is shown by default */
  margin-bottom: 5px;
  margin-top: 5px;
}

/* Mobile logo styles */
.mobileLogo {
  display: none; /* Hide mobile logo by default */
}

/* Navigation buttons container */
.navButtons {
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Center the buttons */
  flex-grow: 1; /* Allow the buttons to grow and fill space */
  align-items: center; /* Align buttons vertically centered */
  gap: 22px; /* Increased space between buttons for better spacing */
  font-weight: bold!important;
  font-size: 33!important;
}

/* Navigation button styles */
.navButton {
  margin: 0 8px; /* Adjust margin to space buttons more closely */
  color: #000000 !important; /* Set text color to black and use !important to override other styles */
  background-color: transparent !important; /* Ensure background is transparent */
  font-weight: bold!important; /* Use bold weight for better readability */
  font-size: 15px!important; /* Increase font size for better readability */
  padding: 12px 20px; /* Increased padding for larger clickable area */
  border: none; /* Remove any border */
  outline: none; /* Remove outline */
  border-radius: 4px; /* Add slight rounding for a modern look */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
  font-family: 'Roboto', sans-serif!important; /* Ensure font consistency */
  
}

.navButton:hover {
  background-color: #f0f0f0; /* Light grey background on hover for visual feedback */
  color: #000; /* Slightly darker text color on hover */
}

/* Compare button container styles */
.compareButtonContainer {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Align items vertically centered */
  margin-left: auto; /* Ensure the container aligns to the right */
  gap: 0px; /* Add space between the compare buttons */
}

/* Compare button styles */
.compareButton {
  background-color: #ffc000 !important; /* Background color */
  color: #ffffff !important; /* Set text color to white */
  font-weight: bold!important; /* Use bold weight */
  font-size: 15px!important; /* Increase font size */
  padding: 12px 20px; /* Increased padding for larger clickable area */
  border: none; /* Remove any border */
  outline: none; /* Remove outline */
  border-radius: 4px; /* Add slight rounding */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  font-family: 'Roboto', sans-serif!important; /* Ensure font consistency */
}

.compareButton:hover {
  background-color: #e6a700 !important; /* Darker gold on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a soft shadow on hover */
}

/* Drawer list styles */
.drawerList {
  width: 220px !important; /* Slightly wider drawer for better readability */
  font-family: 'Roboto', sans-serif!important; /* Apply Roboto font */
}

/* Drawer text styles */
.drawerText {
  text-align: center; /* Center the text */
  color: #333333 !important; /* Softer black for better readability */
  font-family: 'Roboto', sans-serif; /* Ensure font consistency */
}

/* Drawer paper styles */
.drawerPaper {
  background-color: #ffffff !important; /* White background */
  font-family: 'Roboto', sans-serif; /* Ensure font consistency */
}

/* Responsive adjustments for 950px and below */
@media (max-width: 1100px) {
  .toolbar {
    padding-top: 0; /* Ensure no extra padding on the toolbar for mobile view */
    justify-content: space-between; /* Space out items evenly */
  }
  .navButtons, .compareButtonContainer {
    display: none; /* Hide navigation buttons and compare button container */
  }
  .desktopLogo {
    display: none; /* Hide desktop logo */
  }
  .mobileLogo {
    display: block; /* Show mobile logo */
    height: 70px; /* Height of the logo for mobile view */
    position: absolute; /* Position the logo absolutely */
    top: 5px; /* Adjust this to push the logo down below the announcement bar */
    left: 50%; /* Center the logo horizontally */
    transform: translateX(-50%); /* Adjust position to ensure centering */
  }
  .menuButton {
    font-size: 30px !important; /* Ensure the size of the menu icon for mobile and tablet */
    height: 80px; /* Adjust the height */
    display: flex;
    align-items: center;
  }
  .menuButton .MuiSvgIcon-root {
    font-size: inherit; /* Inherit the font size from the parent */
  }
}
