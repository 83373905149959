@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.slide {
  position: relative;
  margin: 0;
  padding: 0;
  border-radius: 0;
  background-size: cover;
  background-position: center;
  min-height: 500px;
  width: 100%;
  box-shadow: none;
  transition: transform 0.3s, box-shadow 0.3s;
  z-index: 1;
}

.slide:hover {
  transform: scale(1.03);
  box-shadow: none;
}

/* Text Overlay Styles */
.textOverlay {
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translateY(-50%);
  color: #ffffff;
  text-align: left;
  padding: 24px;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  max-width: 70%;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s;
  z-index: 2;
  animation: moveUpAndDown 3s infinite ease-in-out; /* Added animation */
}

@keyframes moveUpAndDown {
  0% {
    top: 50%;
    transform: translateY(-50%);
  }
  50% {
    top: 40%;
    transform: translateY(-50%);
  }
  100% {
    top: 50%;
    transform: translateY(-50%);
  }
}

.textOverlay:hover {
  background-color: rgba(0, 0, 0, 0.85);
}

.textOverlay.right {
  left: auto;
  right: 10%;
  text-align: right;
}

.textOverlay.center {
  left: 50%;
  right: auto;
  text-align: center;
  transform: translate(-50%, -50%);
}

@media (max-width: 600px) {
  .textOverlay {
    left: 50%;
    right: auto;
    text-align: center;
    transform: translate(-50%, -50%);
    padding: 16px;
    max-width: 90%;
  }
}

/* Typography */
.textOverlay h3 {
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 10px;
}

.textOverlay h5 {
  color: #ffc000; /* Updated color */
  margin-bottom: 10px;
}

.textOverlay p {
  color: #ffffff;
  margin-bottom: 10px;
}

.textOverlay .highlight {
  color: #ffc000; /* Updated color */
  margin-bottom: 15px;
}

/* Custom Button */
.custom-button {
  background-color: #ffc000; /* Updated color */
  color: #ffffff;
  text-transform: none;
  font-weight: bold;
  padding: 10px 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  z-index: 3;
}

.custom-button:hover {
  background-color: #cc8400; /* Retained a slightly darker hover effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

