/* src/styles/Policy.module.css */
.policyContainer {
    padding: 20px;
    max-width: 900px;
    margin: auto;
    background-color: #c4c4c4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .policyContent {
    margin-top: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .downloadButton {
    background-color: #ffc000; /* Set button color to #ffc000 */
    color: #ffffff;
    text-transform: none;
    font-weight: bold;
    padding: 10px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  }