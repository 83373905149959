.announcementBar {
  background-color: #c4c4c4;
  padding: 10px 20px; /* Increased padding for better spacing */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 11px;
  color: #000000;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
  z-index: 1;
}

.contactInfo {
  display: flex;
  flex-direction: column; /* Stack text vertically on small screens */
  flex: 1; /* Takes up available space */
  margin-bottom: 0px; /* Adds space below on smaller screens */
}

.contactText {
  margin-bottom: 5px; /* Space between text lines */
  font-weight: bold !important;
  font-size: small !important;
}

.contactLink {
  color: #000000;
  text-decoration: none;
  margin: 0 5px;
  transition: color 0.3s ease; /* Smooth transition for hover */
}

.contactLink:hover {
  text-decoration: underline;
  color: #007BFF; /* Changes to a more noticeable color on hover */
}

.socialMediaIcons {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icons */
}

.icontexts {
  font-weight: bold;
  font-size: medium;
  margin-left: 5px; /* Add some space between text and icon */
}

.socialMediaIcons .MuiIconButton-root {
  color: #000000;
  padding: 5px;
  align-items: center;
  transition: transform 0.2s ease, color 0.3s ease; /* Adds smooth transition for hover effects */
}

.socialMediaIcons .MuiIconButton-root:hover {
  color: #3b5998; /* Facebook blue color */
  transform: scale(1.1); /* Slightly enlarge icon on hover */
}

.socialMediaIcons .MuiSvgIcon-root {
  font-size: 1.5rem; /* Standardize icon size */
  vertical-align: middle; /* Align icons vertically with text */
}

/* Responsive adjustments for mobile devices */
@media (max-width: 600px) {
  .announcementBar {
    flex-direction: column; /* Stack elements vertically on small screens */
    align-items: flex-start; /* Align items to start */
    padding: auto; /* Reduced padding */
  }

  .socialMediaIcons {
    order: -1; /* Move social icons to the top on small screens */
    margin-bottom: 0px; /* Adds space below the social icons */
    justify-content: center; /* Center align the icons */
    width: 100%; /* Make icons take full width */
    padding: auto; /* Reduced padding */
  }

  .contactInfo {
    width: 100%; /* Make contact info take full width */
    height: 70%;
  }
}
