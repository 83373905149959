/* General Styles */
.slide {
  position: relative;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-position: center;
  min-height: 800px;
  width: 100%;
  box-shadow: none;
  transition: transform 0.3s, box-shadow 0.3s;
  z-index: 1;
}

.slide:hover {
  transform: scale(1.03);
  box-shadow: none;
}

/* Text Overlay Styles */
.textOverlay {
  position: absolute;
  top: 0%;
  left: 10%;
  transform: translate(-50%, 0);
  color: #fff;
  text-align: center;
  z-index: 2;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.7);
}

@media (min-width: 960px) {
  .textOverlay {
    top: 50%;
    left: 10%;
    right: auto;
    text-align: left;
    transform: translateY(-50%);
    padding: 4px;
    backdrop-filter: blur(10px);
    border-radius: 15px;
    max-width: 70%;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s;
    animation: moveUpAndDown 3s infinite ease-in-out;
  }
}

@keyframes moveUpAndDown {
  0% {
    top: 50%;
    transform: translateY(-50%);
  }
  50% {
    top: 40%;
    transform: translateY(-50%);
  }
  100% {
    top: 50%;
    transform: translateY(-50%);
  }
}

.textOverlay:hover {
  background-color: rgba(0, 0, 0, 0.85);
}

.textOverlay.right {
  left: auto;
  right: 10%;
  text-align: right;
}

.textOverlay.center {
  left: 50%;
  right: auto;
  text-align: center;
  transform: translate(-50%, -50%);
}

.deposit {
  color: #ffc000; /* Deposit text in yellow */
  margin-bottom: 15px;
}

@media (max-width: 600px) {
  .textOverlay {
    top: 0; 
    left: 50%;
    transform: translate(-50%, 0); 
    width: 90%;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.7); 
    backdrop-filter: blur(10px);
    border-radius: 0 0 15px 15px; 
  }

  .textOverlay h4 {
    font-size: 1.5rem; 
  }

  .textOverlay h6 {
    font-size: 1.2rem; 
  }

  .textOverlay p {
    font-size: 0.875rem; 
  }

  .GradientButton {
    padding: 10px 12px; 
    font-size: 0.775rem; 
  }
}
