/* Info Section */
.infoSection {
  padding: 0px 0px;
  background-color: rgba(244, 244, 244, 0.9); /* Slightly transparent background */
  position: relative;
  width: 100%; /* Full width */
}

.infoSection::before {
  margin-top: 5px;
  content: '';
  position: absolute;
  top: -59px; /* Adjust to control the height of the triangle */
  left: 50%;
  transform: translateX(-50%);
  width: 500;
  height: 0;
  border-left: 550px solid transparent; /* Adjust to control the width of the triangle */
  border-right: 550px solid transparent; /* Adjust to control the width of the triangle */
  border-bottom: 60px solid rgba(244, 244, 244, 244); /* Adjust to control the height and color of the triangle */
}

.infoContent {
  max-width: auto;
  text-align: center;
}

.iconsContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
  flex-wrap: wrap; /* Ensure icons wrap on smaller screens */
}

.iconsContainer img {
  max-width: 0px; /* Increased the size of the icons */
}

.infoItem {
  text-align: center;
  margin: 0px;
}

.infoItem h5, h4 {
  font-size: 1.5rem; /* Adjust heading size */
  font-weight: bold; /* Make heading bold */
  font-family: 'Roboto', sans-serif!important;
}

.infoItem p {
  font-size: 1.2rem; /* Adjust paragraph size */
  font-family: 'Roboto', sans-serif;
}

/* Responsive adjustments */
@media (max-width: 10000px) {
  .infoSection {
    padding: 0px 0px; /* Adjust padding for mobile */
  }
  .infoContent {
    width: 100%; /* Ensure consistent alignment with other sections */
    margin: 0 auto; /* Center the section */
  }
  .infoItem {
    margin: 0px 0; /* Adjust margin for mobile */
  }
  .infoItem h5 {
    font-size: 1.2rem; /* Adjust heading size for mobile */
  }
  .infoItem p {
    font-size: 1rem; /* Adjust paragraph size for mobile */
  }
}
