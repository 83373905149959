/* Import Roboto font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

/* Apply the font family to the entire document */
body, html {
  font-family: 'Roboto', sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Optional: Additional global styles */
* {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400;
  color: #000; /* Default text color */
}

/* Adjust headings for consistent look */
h1, h2, h3, h4, h5, h6 {
  font-weight: bold !important;
  color: #000 !important; /* Ensuring all headings use the same style */
}

.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f4f4f4;
  padding: 10px;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.cookie-banner p {
  margin: 0;
  font-size: 14px;
  color: #000;
}

.cookie-banner a {
  color: #007BFF;
  text-decoration: underline;
}

.accept-button {
  padding: 5px 10px;
  background-color: #ffc000;
  color: #000;
  border: none;
  cursor: pointer;
  font-weight: bold;
}
