/* src/styles/VehicleIcons.module.css */

.iconContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Adjust gap between icons */
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9; /* Light background for the container */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.iconBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 150px; /* Adjust width as needed */
  padding: 20px;
  background-color: #ffffff; /* White background for each icon box */
  border-radius: 10px;
  text-decoration: none; /* Remove link underline */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
}

.iconBox:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

.iconImage {
  width: 80px; /* Adjust size for images */
  height: auto;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.iconImage:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.iconBox > .MuiTypography-root {
  color: #000000; /* Text color */
  font-weight: 600;
  margin-top: 10px;
}
