/* Import Roboto font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

.container {
  padding: 16px !important;
  font-family: 'Roboto', sans-serif !important;
  max-width: 1200px !important;
  margin: 0 auto !important;
}

.header {
  background: linear-gradient(135deg, #c4c4c4, #e6e6e6) !important;
  padding: 20px !important;
  color: #333 !important;
  text-align: center !important;
  border-radius: 10px !important;
  margin-bottom: 20px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.header h4 {
  font-size: 2.2rem !important;
  font-weight: bold !important;
  margin-bottom: 8px !important;
  text-transform: uppercase !important;
  color: #000 !important;
}

.navLinks {
  display: flex !important;
  justify-content: center !important;
  gap: 12px !important;
  flex-wrap: wrap !important;
  margin-bottom: 10px !important;
}

.navLinks a {
  padding: 10px 15px !important;
  text-decoration: none !important;
  color: #000 !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  transition: background-color 0.3s, transform 0.3s !important;
  font-family: 'Roboto', sans-serif !important;
}

.navLinks a:hover {
  background-color: #ffc000 !important;
  color: #000 !important;
  transform: translateY(-3px) !important;
}

.section {
  margin-bottom: 20px !important;
}

.assistanceImage {
  width: 100% !important;
  height: auto !important;
  max-height: 350px !important;
  object-fit: cover !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1) !important;
}

.infoButton {
  margin: 12px 0 !important;
  background-color: #ffc000 !important;
  color: #000 !important;
  font-weight: bold !important;
  padding: 14px 28px !important;
  transition: background-color 0.3s, box-shadow 0.3s !important;
  border-radius: 6px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15) !important;
}

.infoButton:hover {
  background-color: #e6a700 !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2) !important;
}

.tableContainer {
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
  gap: 20px !important;
}

.table {
  flex: 1 1 45% !important;
  margin: 16px 0 !important;
  background-color: #f9f9f9 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  transition: transform 0.3s !important;
}

.table:hover {
  transform: scale(1.02) !important;
}

.tableHeader {
  background-color: #ffc000 !important;
  color: #000 !important;
  padding: 12px !important;
  font-size: 1.2rem !important;
  font-weight: bold !important;
}

.galleryTitle {
  margin-top: 40px !important;
  color: #000 !important;
  font-size: 1.8rem !important;
  font-weight: bold !important;
  text-align: center !important;
}

.gallery {
  display: grid !important;
  gap: 16px !important;
  margin-top: 32px !important;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
}

.galleryItem {
  position: relative !important;
  width: 100% !important;
  height: 200px !important;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  transition: transform 0.3s ease-in-out !important;
  cursor: pointer !important;
}

.galleryItem:hover {
  transform: scale(1.05) !important;
}

.fullscreenIcon {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  width: 24px !important;
  height: 24px !important;
  z-index: 2 !important;
  color: #ffc000 !important;
  cursor: pointer !important;
}

@media (max-width: 600px) {
  .header h4 {
    font-size: 1.5rem !important;
  }

  .table {
    flex: 1 1 100% !important;
    margin: 8px 0 !important;
  }

  .infoButton {
    padding: 10px 20px !important;
  }

  .galleryItem {
    height: 150px !important;
  }

  .assistanceImage {
    max-height: 300px !important;
  }
}
