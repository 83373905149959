.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .contactPaper {
    padding: 20px;
    background-color: #f9f9f9;
    margin-top: 20px;
    border-radius: 10px;
  }
  
  .form {
    margin-top: 20px;
  }
  
  .contactLink {
    color: inherit;
    text-decoration: none;
  }
  
  .contactLink:hover {
    text-decoration: underline;
  }
  
  .button {
    background-color: #ffc000 !important; /* Force the button color */
    color: #ffffff !important; /* Set text color for better contrast */
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: none; /* Remove Material-UI button shadows if any */
  }
  
  .button:hover {
    background-color: #e6a700 !important; /* Slightly darker shade on hover */
  }
  
  